import { CATEGORY_ID, CHOICE_PREFERENCE, CONF_REQUIRED, CONNECTOR_ID, FIELD_DATA_CONFIGURATION, FIELD_HIDDEN, FIELD_ID, FIELD_NAME, FIELD_TYPES, FRMDATA_FIELD_TYPE, FRMDATA_GROUP_LABEL, FRMDATA_ITEMS, FRMDATA_TYPE, MODEL_CATEGORIES, MODEL_CONNECTORS, MODEL_DATA, MODEL_DYNAMIC_FIELDS, MODEL_FIELDS, MODEL_SUBMODEL_ID, PAGE_LAYOUT, PAGE_LAYOUTS, PLACEHOLDER_IMAGE, RUNTIME_ELEMENT_TYPES, RUNTIME_INSTANCE_ELEMENT_TYPE, RUNTIME_INSTANCE_FIELD_REQUIRED, RUNTIME_INSTANCE_FIELD_VALUE, RUNTIME_INSTANCE_INSTANCE_ID, RUNTIME_INSTANCE_IS_HIDDEN, RUNTIME_INSTANCE_IS_INPUT, RUNTIME_INSTANCE_REQUIRED, ZIP_CODE_FIELD } from "constants/constants"
import { TModel, TProductCategory, TRuntimeInstance, TUI_BuilderSubmodel, TUI_Model, TUI_Node } from "types"
import Field from "./Field"
import Summary from "components/Summary/Summary"
import { isArray } from "lodash"
import Categories from "./Category/Categories"
import { useAppContext } from "contexts/AppContext"
import { Fragment } from "react"
import NodeHelper from "helpers/NodeHelper"
import { isField } from "@apollo/client/utilities"
import { TCartItem } from "types/Cart"
import Utils from "Services/Utils"
import Title from "components/Header/Title"
import { GrMapLocation } from "react-icons/gr";

type MyProps = {
    model: TModel,
    instance: TRuntimeInstance,
    visibleFields: string[]
    visibleConnectors: string[]
    visibleCategories: string[]
    uiModel: TUI_BuilderSubmodel
}

const Submodel = ({model, instance, visibleFields, visibleConnectors, visibleCategories, uiModel }: MyProps) => {
    const {
        activePage,
        theme,
        specs,
        cart,
        selectedValues
    } = useAppContext()

    const summaryData = Utils.generateSummaryData(
        model?.[MODEL_DATA][MODEL_FIELDS], 
        instance,
        visibleFields,
    )

    const isFieldsDone = NodeHelper.checkIfSubmodelIsDoneByRuntimeSpec(instance, activePage?.[PAGE_LAYOUT], true)

    const _fieldGroups = uiModel[FRMDATA_ITEMS]
        .filter((fieldB) => {
            const fieldR = instance[MODEL_DYNAMIC_FIELDS][fieldB[FIELD_ID]]

            if(!fieldR) return false

            if(fieldR[RUNTIME_INSTANCE_ELEMENT_TYPE] === RUNTIME_ELEMENT_TYPES.FIELD) 
                return (fieldR[RUNTIME_INSTANCE_IS_HIDDEN] === false) &&
                    fieldR[RUNTIME_INSTANCE_IS_INPUT]

            return fieldR[RUNTIME_INSTANCE_IS_HIDDEN] === false
        })
        .reduce((acc: (TUI_Node|TUI_Node[])[], node) => {
            if(!node[FRMDATA_GROUP_LABEL] || node[FRMDATA_TYPE] === FIELD_TYPES.CATEGORY) {
                acc.push(node)
                return acc
            }

            if(
                acc.length === 0 
                || !isArray(acc[acc.length - 1]) 
                ||(acc as TUI_Node[][])[acc.length - 1][0][FRMDATA_GROUP_LABEL] !== node[FRMDATA_GROUP_LABEL]
            ) {
                acc.push([node])
                return acc
            }

            (acc as TUI_Node[][])[acc.length - 1].push(node)
            return acc
        }, [])

    const fieldGroups = activePage?.pageName === "Property Location" ? 
    [
        ..._fieldGroups.slice(0, 1),
        {id: "map", fieldType: "map", type: "map"},
        ..._fieldGroups.slice(1)
    ]
    : _fieldGroups


    const isValidUSZipCode = (zipCode: string): boolean => {
        const zipRegex = /^\d{5}(-\d{4})?$/;
        return zipRegex.test(zipCode);
    };

    const findZipCode = () => {
        const zipCodeKey = selectedValues && Object.keys(selectedValues).find(key => key.includes(ZIP_CODE_FIELD));
        return zipCodeKey ? selectedValues?.[zipCodeKey][0] : null;
    };
    
    return (
    <>
        {
            fieldGroups
            .map((fieldB, key) => {
                if(isArray(fieldB)){
                    return <div key={key} className={`sb3-space-y-1 sb3-relative`}>
                        <Title title={fieldB[0][FRMDATA_GROUP_LABEL]} titleClassName="!sb3-text-lg !sb3-font-semibold "/>
                        <div className="sb3-flex sb3-flex-wrap sb3-gap-x-5 sb3-gap-y-2">
                        {
                            fieldB.map((node, key1) => {
                                const fieldR = instance[MODEL_DYNAMIC_FIELDS][node[FIELD_ID]]

                                if(fieldR[RUNTIME_INSTANCE_ELEMENT_TYPE] === RUNTIME_ELEMENT_TYPES.FIELD)
                                    return <Field 
                                        required={fieldR[RUNTIME_INSTANCE_FIELD_REQUIRED]}
                                        instance={instance} 
                                        fieldB = {node} 
                                        key={key1}
                                        submodel={model}
                                        uiModel={uiModel}
                                        showType={node[FRMDATA_FIELD_TYPE]}
                                    />

                                return <Fragment key = {key}/>

                            })
                        }
                        </div>
                    </div>
                }

                if(activePage?.pageName === "Property Location" && fieldB[FRMDATA_TYPE] === "map") {
                    const zipcode = findZipCode()
                    if(zipcode && Object.keys(CHOICE_PREFERENCE).includes(zipcode as string)) return <div className="field sb3-row-span-6 sb3-w-full sb3-h-full">
                        <Title title="Map"/>
                        <img src={zipcode === "30265" ? "map1.jpg" : "map2.jpg"} alt="Map view" className="sb3-w-full sb3-h-full sb3-object-cover"/>
                    </div>

                    return <div className="sb3-w-full sb3-row-span-6 sb3-space-y-2">
                        <Title title="Map"/>

                        <div className="sb3-flex sb3-items-center sb3-justify-center sb3-flex-col sb3-aspect-square sb3-bg-gray-100">
                            <GrMapLocation className="sb3-text-7xl"/>
                            <p>Type your ZIP code to view location on map</p>
                        </div>
                    </div>
                }

                const fieldR = instance[MODEL_DYNAMIC_FIELDS][fieldB[FIELD_ID]]

                if(fieldB[FRMDATA_TYPE] === FIELD_TYPES.CATEGORY) {
                    let selectedProducts: TCartItem[] = []
    
                    selectedProducts = Utils.getSelectedProductsFromCategoryIds(cart, specs[model[MODEL_SUBMODEL_ID]][MODEL_DATA][MODEL_CATEGORIES].map((cat: TProductCategory) => cat[CATEGORY_ID]), instance[RUNTIME_INSTANCE_INSTANCE_ID])

                    const _categories = model?.[MODEL_DATA][MODEL_CATEGORIES].filter((cat) => fieldB.id === cat[CATEGORY_ID] && visibleCategories.includes(cat[CATEGORY_ID]))

                    if(_categories.length === 0) return <></>

                    return (
                        <Categories 
                            key={key} 
                            submodel = {model} 
                            categories = {_categories}
                            selectedProducts = {selectedProducts}
                            instance={instance}
                            uiModel={uiModel}
                            load={
                                !Utils.isLinear(theme) 
                                ||isFieldsDone
                            }
                        />
                    )
                }

                // if(fieldB[FRMDATA_TYPE] === FIELD_TYPES.CONNECTOR) return (
                //     <Connector 
                //         instance={instance} 
                //         connector = {connector} 
                //         key={key}
                //     />
                // )
                
                if(fieldR[RUNTIME_INSTANCE_ELEMENT_TYPE] === RUNTIME_ELEMENT_TYPES.FIELD) return <Field 
                    required={fieldR[RUNTIME_INSTANCE_FIELD_REQUIRED]}
                    instance={instance} 
                    fieldB = {fieldB as TUI_Node} 
                    key={key}
                    submodel={model}
                    uiModel={uiModel}
                    showType={fieldB[FRMDATA_FIELD_TYPE]}
                />
                
                return <Fragment key = {key}/>
            })
        }

        {
            summaryData.length > 0 && <Summary title="Project Requirements"
                items={summaryData}/>
        }
    </>
    )
}

export default Submodel