import Utils from "Services/Utils";
import Title from "../Header/Title";
import React, {useEffect, useState} from "react";
import { useAppContext } from "contexts/AppContext";
import { PAGE_LAYOUT, PAGE_LAYOUTS } from "constants/constants";
import DeckDesigner from "components/Maps/GMapsWithGeometry";
import FillButton from "components/Button/FillButton";

type MyProps = {
    id: string;
    name: string
    value: string|number
    onChange: (_value: string) => void
    placeholder?: string
    disabled?: boolean
    required?: boolean
    error?: string
    maxLength?: number|string
    description?: string
    info?: string
}

const TextField = (props: MyProps) => {
    const {activePage} = useAppContext()
    const [_value, setValue] = useState("")
    const [error, setError] = useState<string|null>(null)

    useEffect(() => {
        setValue(`${props.value}`)
    }, [props.value])

    const submit = () => {
        const _error = Utils.validateStringField(_value, {maxLength: Number(props.maxLength)})
        setError(_error)
        if(!_error && props.value !== _value) props.onChange(_value)
    }

    const handleBlur: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        submit()
    }

    const handleKeyPress: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
        if(e.key === 'Enter'){
            e.preventDefault()
            submit()
        }
    }

    return (
        <>
            <div className="sb3-space-y-2">
                <Title title={props.name} required={props.required} description={props.description} info={props.info}/>
                <div>
            
                <input disabled={props.disabled} onBlur={handleBlur} maxLength={props.maxLength ? Number(props.maxLength) : undefined} onKeyDown={handleKeyPress} onChange={(e) => setValue(e.target.value)} value={_value} type="text" id="default-input" placeholder={props.placeholder && ''}
                        className={`disabled:sb3-bg-disabled sb3-bg-white sb3-border sb3-border-gray-300 sb3-text-gray-900 sb3-text-sm sb3-rounded-none focus:sb3-ring-0 focus:sb3-outline-none sb3-block ${(activePage?.[PAGE_LAYOUT] === PAGE_LAYOUTS.GUIDED_SELLING) ? '!sb3-w-80' : 'sb3-w-full'} sb3-max-w-full sb3-p-2.5`}/>

                {error &&
                    <p id="helper-text-explanation" className="sb3-ml-2 sb3-text-sm sb3-text-red-500">{error}</p>}
                </div>
            </div>
        </>
    )
};

export default TextField