import Utils from "Services/Utils"
import { BUILDER_CONFIG, CONFIG_CUSTOMIZATION, CUSTOMIZATION_THEME_SETTINGS,  MODEL_DYNAMIC_FIELDS, RESULT_PRDT_CATEGORY, RESULT_PRDT_INSTANCEID, RESULT_PRDT_QTY, RESULT_PRDT_SKU, RUNTIME_INSTANCE_MAX_QUANTITY, THEME_SUBTITLES } from "constants/constants"
import { useAppContext } from "contexts/AppContext"
import CompatioAnalytics from "helpers/CompatioAnalyticsSb3"
import ProductHelper from "helpers/ProductHelper"
// import { AnimatePresence } from "framer-motion"
import CartItem from "./CartItem"
import FillAnchor from "components/Button/FillAnchor"
import { TCartItem } from "types/Cart"
import LinearPreview from "components/LinearPreview"
import AccordionLayout from "components/Accordion/AccordionLayout"
import AccordionHeader from "components/Accordion/AccordionHeader"
import AccordionBody from "components/Accordion/AccordionBody"
// import { useLocation, useNavigate } from "react-router-dom"

const Cart = () => {
    const {
        cart,
        getCartTotalPrice,
        runtimeSpec,
        builderSpec,
        handleReset,
        setLoadingToCart,
        setIsOpen,
        theme,
        categoryLabels,
        modelId,
        modelSpec
    } = useAppContext()

    // const location = useLocation()
    // const navigate = useNavigate()
    
    const handleCartAdd = () => {
        // const params = new URLSearchParams(location.search);

        // if (params.has('smartBuilder')) {
        //     params.delete('smartBuilder');
        // }
        // if (params.has('blank')) {
        //     params.delete('blank');
        // }
        // const newUrl = `${location.pathname}?${params.toString()}`;

        // Update the URL with the new parameters
        // navigate(newUrl, { replace: true });

        let dataFilterdForAnalytic: any = [];
        cart.forEach(cartItem => {
            dataFilterdForAnalytic.push({
                baseFinalProductID: Utils.getClientProductSku(),
                baseParentProductID: Utils.getClientProductSku(),
                baseFinalProductName: window.compatioConfig?.compatibleProducts?.productName && decodeURIComponent(window.compatioConfig.compatibleProducts.productName),
                finalProductID: cartItem[RESULT_PRDT_SKU],
                parentProductID: cartItem[RESULT_PRDT_SKU],
            });
        });

        CompatioAnalytics.track(
            CompatioAnalytics.events.BuilderClick,
            { products: dataFilterdForAnalytic },
            'SmartBuilder3',
            undefined,
            'V1',
            modelSpec?.project.ISRName ?? "",
            modelId
        );

        CompatioAnalytics.trackNew(
            CompatioAnalytics.events.BuilderClick,
            { products: dataFilterdForAnalytic },
            'SmartBuilder3',
            undefined,
            'V1',
            modelSpec?.project.ISRName ?? "",
            modelId
        )

        setLoadingToCart(true)
        // window.location.replace(newUrl);
    }
    // h-[750px] min-h-max max-h-[min(calc(100vh-_140px),_750px)]

    const cartGroups = cart.reduce((acc: {[x: string]: TCartItem[]}, item) => {
        if(!(categoryLabels[item.catgeory_id] in acc)) acc[categoryLabels[item.catgeory_id]] = []
        acc[categoryLabels[item.catgeory_id]].push(item)
        return acc
    },{})

    return (
        <div className={`${Utils.isLinear(theme) ? "sb3-top-0 sb3-min-h-screen sb3-max-h-screen sb3-h-min sb3-bg-gray " : "sb3-bg-white sb3-border sb3-border-black sb3-top-[65px] sb3-max-h-[min(calc(100vh-_214px),_750px)] sb3-overflow-auto "}  sb3-sticky sb3-text-left sb3-flex sb3-flex-col sb3-justify-between sb3-min-w-[270px] sb3-min-h-[350px]`}>
            {
                Utils.isLinear(theme) &&
                <LinearPreview/>
            }
            <div className="sb3-flex sb3-flex-col sb3-flex-grow sb3-min-h-[250px] sb3-overflow-auto ">
                <h3 className='sb3-font-bold sb3-flex sb3-items-center sb3-space-x-2 sb3-border-b sb3-py-2.5 sb3-w-full'>
                    <span className="sb3-px-2" style={{color: builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_SUBTITLES]}}>Your Build</span> 
                    {!Utils.isLinear(theme) && <span className="sb3-px-2 sb3-py-1 sb3-bg-gray-200 sb3-text-sm sb3-rounded-full sb3-border-b">{cart.length}</span>}
                </h3>

                {/* Cart Products */}
                <div className={`sb3-flex-grow sb3-overflow-auto ${!Utils.isLinear(theme) && 'sb3-px-2'}`}>
                    {/* <AnimatePresence> */}
                    {
                        !Utils.isLinear(theme) && 
                        cart.map((product, key) => {
                            const isNextActive = (runtimeSpec?.[ProductHelper.getProductInstanceId(product)]?.[MODEL_DYNAMIC_FIELDS][ProductHelper.getProductCategoryId(product)][RUNTIME_INSTANCE_MAX_QUANTITY] ?? 1) <= (Utils.getSelectedProductsFromCategory(cart, product[RESULT_PRDT_CATEGORY], product[RESULT_PRDT_INSTANCEID])?.reduce((acc, product) => acc + product.quantity ,0) ?? 0)
                            
                            return (
                                <CartItem
                                    key = {key}
                                    product={product}
                                    categoryId={product[RESULT_PRDT_CATEGORY]}
                                    instanceId = {product[RESULT_PRDT_INSTANCEID]}
                                    isNextActive={isNextActive}
                                    isQtyActive={(runtimeSpec?.[product[RESULT_PRDT_INSTANCEID]]?.[MODEL_DYNAMIC_FIELDS][product[RESULT_PRDT_CATEGORY]][RUNTIME_INSTANCE_MAX_QUANTITY] ?? 1) > 1}
                                />
                            )
                        })
                    }
                    {
                        Utils.isLinear(theme) && 
                        Object.entries(cartGroups)?.map(([groupName, group], key) => {
                            return (
                                <div className="sb3-space-y-1" key={"cartgroup" + key}>
                                    <span className="sb3-text-xs sb3-text-gray-extraDark">{groupName}</span>
                                    {
                                        group.map((product, key) => {
                                            const isNextActive = (runtimeSpec?.[ProductHelper.getProductInstanceId(product)]?.[MODEL_DYNAMIC_FIELDS][ProductHelper.getProductCategoryId(product)][RUNTIME_INSTANCE_MAX_QUANTITY] ?? 1) <= (Utils.getSelectedProductsFromCategory(cart, product[RESULT_PRDT_CATEGORY], product[RESULT_PRDT_INSTANCEID])?.reduce((acc, product) => acc + product.quantity ,0) ?? 0)

                                            return (
                                                <CartItem
                                                    key = {key}
                                                    product={product}
                                                    categoryId={product[RESULT_PRDT_CATEGORY]}
                                                    instanceId = {product[RESULT_PRDT_INSTANCEID]}
                                                    isNextActive={isNextActive}
                                                    isQtyActive={(runtimeSpec?.[product[RESULT_PRDT_INSTANCEID]]?.[MODEL_DYNAMIC_FIELDS][product[RESULT_PRDT_CATEGORY]][RUNTIME_INSTANCE_MAX_QUANTITY] ?? 1) > 1}
                                                />
                                        )})
                                    }
                                </div>
                            )
                        })
                    }
                    {/* </AnimatePresence> */}
                </div>
            </div>

            <div className={`sb3-space-y-2 sb3-py-2 sb3-px-2 sb3-border-t sb3-sticky  first:sb3-bg-gray-200 first:sb3-border-b sb3-shadow-lg sb3-bg-white  ${!Utils.isLinear(theme) ? "sb3-bottom-0" : "sb3-bottom-2"}`}>
                <div className={`${!Utils.isLinear(theme) ? "sb3-bg-gray-200 sb3-border-b  sb3-px-2" : ""} sb3-font-semibold sb3-py-1.5 sb3-w-full sb3-flex sb3-justify-between sb3-items-center sb3-text-sm sb3-rounded-sm`}>
                    <span>Subtotal:</span> 
                    <span>{Utils.formatPrice(getCartTotalPrice())}</span>
                </div>
                
                {
                    (Utils.checkIfClientWebsite()) &&
                    <>
                        {
                            !Utils.isShopify() &&
                                <AccordionLayout initialState={true}>
                                {
                                    (provided) => ( 
                                        <>
                                            <AccordionHeader  {...provided.getHeaderProps()}><strong className="sb3-text-gray-extraDark sb3-text-sm">Disclaimer:</strong></AccordionHeader>
                                            <AccordionBody  {...provided.getBodyProps()}>
                                                <p className="sb3-text-xs sb3-text-gray-extraDark"> {!Utils.isLinear(theme) ? "Compatio" : "Vision"} provides product configurations and recommendations as a guide. The customer is solely responsible for validating the application, operation, maintenance, and compliance of all components. {!Utils.isLinear(theme) ? "Compatio" : "Vision"} does not guarantee suitability for your specific application and assumes no responsibility for your product design, installation, or operation.</p>
                                            </AccordionBody>
                                        </>
                                    )
                                }
                                </AccordionLayout>
                        }
                        

                        <FillAnchor 
                            id="addoutfittocart"  
                            className="sb3-w-full dc-outfit-button sb3-cursor-pointer sb3-flex sb3-items-center sb3-justify-center"
                            productsadded={cart?.map(product => product[RESULT_PRDT_SKU]).join(",")}
                            productsquantity={cart.map(product => product[RESULT_PRDT_QTY]).join(",")}
                            recommendationsource="SmartBuilder3"
                            data-userid={CompatioAnalytics.getUserId()}
                            onClick={handleCartAdd}
                            modelVersion="V1"
                            reelid="-1"
                            modelid={modelId}
                            modelshortname={modelSpec?.project.ISRName}
                        >ADD TO CART</FillAnchor>
                    </>
                }
            </div>
                

            <a
                id="addoutfittocartclear"
                className="addoutfittocartclear"
                onClick={() => {
                    setLoadingToCart(false)
                    handleReset(false)
                    if(setIsOpen) setIsOpen(false)
                }}
            ></a>
        </div>
    )
}

export default Cart