import { useAppContext } from "contexts/AppContext"
import {Link, useNavigate} from 'react-router-dom'
import { FaBars, FaTimes } from "react-icons/fa"
import { IoMdRefresh } from "react-icons/io";
import { BUILDER_CONFIG, CMPT_CONF_CLIENT_LOGO_URL, CMPT_CONF_COMPATIO_CONF, CMPT_CONF_MAGENTO, CMPT_CONF_SHOPIFY, CONFIG_CUSTOMIZATION, CUSTOMIZATION_NAVIGATION_TYPE, CUSTOMIZATION_THEME_SETTINGS, DEFN_PROJECT, NAVIGATION_TYPE, THEMES, THEME_PRIMARY_COLOR, THEME_TITLE } from "constants/constants"
import SkeletonElement from "components/Table/components/SkeletonElement"
import BorderlessButton from "components/Button/BorderlessButton"
import AuthManager from "Services/AuthManager"
import Utils from "Services/Utils"
import BorderedButton from "components/Button/BorderedButton"
import FillButton from "components/Button/FillButton";
import LinearPages from "./LinearPages";
import { IoLogOutOutline } from "react-icons/io5";

const Navbar = () => {
    const {
        setSidebarToggle,
        handleReset,
        theme,
        builderSpec,
        loading,
        isCloseModalOpen,
        setIsCloseModalOpen,
        isPreview,
        modelSpec
    } = useAppContext()

    const navigate = useNavigate()

    const handleLogout = () => {
        AuthManager.logoutUser()
        navigate('/', {replace: true})
    }

    return (
        <nav className={Utils.isConfigurator(theme) ? "sb3-bg-white sb3-border-b" : 'sb3-bg-primary-hover sb3-pb-16'}>
        <div className={`sb3-max-w-navbar sb3-m-auto ${Utils.isConfigurator(theme) ? 'sb3-py-0.5 sb3-flex sb3-justify-between sb3-items-center' : 'sb3-py-3'} sb3-px-3 sb3-max-h-full`}>
            <div className="sb3-flex sb3-justify-between sb3-items-start">
                <div className="sb3-flex sb3-space-x-5">
                {
                    theme === THEMES.APPLICO && 
                    <button
                        type="button"
                        onClick={() => setSidebarToggle(prev => !prev)}
                        className="sb3-block lg:sb3-hidden"
                    >
                        <FaBars/>
                    </button>
                }
                <Link
                    to='/'
                    className="sb3-text-primary"
                    style={{
                        color: builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_TITLE]
                    }}
                >
                    {
                        !loading && (
                        (Utils.isConfigurator(theme) || Utils.isLinear(theme))?
                            <img
                                src={(
                                    !!window[CMPT_CONF_COMPATIO_CONF] ? (window[CMPT_CONF_COMPATIO_CONF]?.[CMPT_CONF_MAGENTO]?.[CMPT_CONF_CLIENT_LOGO_URL] ?? window[CMPT_CONF_COMPATIO_CONF]?.[CMPT_CONF_SHOPIFY]?.[CMPT_CONF_CLIENT_LOGO_URL]) :
                                    '/compatio.png'
                                )}
                                alt="Logo"
                                className={Utils.isConfigurator(theme) ? "sb3-max-h-logo" : ""}
                            />
                            :
                            <h1 className="sb3-logo sb3-text-lg sb3-font-semibold focus:sb3-outline-none">
                                Sky Windows
                            </h1>
                        )
                    }
                    {
                        loading && 
                        <SkeletonElement type="text50"/>
                    }
                </Link>
                </div>
                {
                    Utils.isLinear(theme) && !Utils.checkIfClientWebsite() && 
                    <BorderlessButton 
                        type="button" 
                        className={!Utils.isConfigurator(theme) ? "sb3-p-1 !sb3-text-white hover:!sb3-text-white sb3-min-h-[30px] sb3-min-w-min sb3-flex sb3-space-x-2 sb3-items-center" : "" }
                        onClick={handleLogout}
                    >
                        <span>Logout</span>
                        {!Utils.isConfigurator(theme) && <IoLogOutOutline/>}
                    </BorderlessButton>
                }
                {
                    Utils.isLinear(theme) && Utils.checkIfClientWebsite() && 
                    <BorderlessButton 
                        type="button" 
                        className={!Utils.isConfigurator(theme) ? "sb3-p-1 !sb3-text-white hover:!sb3-text-white sb3-min-h-[30px] sb3-min-w-min sb3-flex sb3-space-x-2 sb3-items-center" : "" }
                        onClick={() => setIsCloseModalOpen(true)}
                    >
                        <span>Close</span>
                        {!Utils.isConfigurator(theme) && <IoLogOutOutline/>}
                    </BorderlessButton>
                }
            </div>

            {
                builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_NAVIGATION_TYPE] === NAVIGATION_TYPE.STEPPER &&
                <h2 className="sm:sb3-block  sb3-hidden">{modelSpec?.[DEFN_PROJECT].ISRName}</h2>
            }
            
            <div className={`sb3-py-2 sb3-flex ${Utils.isConfigurator(theme) ? 'sb3-justify-end sb3-items-center' : "sb3-justify-between sb3-w-full sb3-items-end sb3-h-full"}`}>
                {
                    Utils.isConfigurator(theme) ?
                    builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_NAVIGATION_TYPE] !== NAVIGATION_TYPE.STEPPER && <FillButton type="button" className="sb3-p-1" onClick={handleReset}>Reset</FillButton>
                        :
                        <>
                            <span></span>
                            <LinearPages/>
                        </>
                }
    
                <div className={Utils.isConfigurator(theme) ? "sb3-flex sb3-space-x-2" : "sb3-space-y-2 sb3-flex sb3-py-2 sb3-flex-col-reverse sb3-justify-between"}>
                    {
                        !Utils.isConfigurator(theme) &&
                        builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_NAVIGATION_TYPE] !== NAVIGATION_TYPE.STEPPER &&
                        <BorderedButton 
                            type="button" 
                            className="sb3-p-1 !sb3-border-white !sb3-text-white !sb3-rounded sb3-min-h-[30px] sb3-min-w-[80px] sb3-flex sb3-space-x-2 sb3-items-center !sb3-bg-transparent" 
                            onClick={handleReset}>
                            <span>Reset</span>
                            <IoMdRefresh/>
                        </BorderedButton> 
                    }
                    {
                        builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_NAVIGATION_TYPE] === NAVIGATION_TYPE.STEPPER && 
                        <BorderedButton 
                            type="button" 
                            className="!sb3-bg-transparent sb3-space-x-2 " 
                            onClick={handleReset}>
                            <IoMdRefresh/>
                            <span>Reset</span>
                        </BorderedButton> 
                    }
                    {
                        builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_NAVIGATION_TYPE] === NAVIGATION_TYPE.STEPPER && 
                        <BorderedButton 
                            type="button" 
                            className={"sb3-p-1 sb3-min-h-[30px] sb3-flex sb3-space-x-2 sb3-items-center sb3-min-w-[80px] "}
                            onClick={() => setIsCloseModalOpen(true)}
                        >
                            <span className="sb3-border sb3-border-black"
                            style={{
                                borderColor: builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_PRIMARY_COLOR]
                            }}
                            ><FaTimes className="sb3-text-xs"/></span>
                            <span>Close</span>
                            {!Utils.isConfigurator(theme) && <IoLogOutOutline/>}
                        </BorderedButton>
                    }
                    {
                        !isPreview && !Utils.isLinear(theme) && !Utils.checkIfClientWebsite() && 
                        <BorderlessButton 
                            type="button" 
                            className={!Utils.isConfigurator(theme) ? "sb3-p-1 !sb3-text-white hover:!sb3-text-white sb3-min-h-[30px] sb3-min-w-[80px] sb3-flex sb3-space-x-2 sb3-items-center" : "" }
                            onClick={handleLogout}
                        >
                            <span>Logout</span>
                            {!Utils.isConfigurator(theme) && <IoLogOutOutline/>}
                        </BorderlessButton>
                    }
                </div>
            </div>
        </div>
    </nav>
    )
}

export default Navbar