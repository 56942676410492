type MyProps = {
    title: string|number|undefined,
    number?: number,
    className?: string,
    required?: boolean
}

const Header = ({title, number, className, required}: MyProps) => {
    return (
        <h3 className={`sb3-text-sm sb3-font-sans sb3-text-black sb3-font-semibold sb3-py-2 ${className ?? ""}`}>{title} 
            {number && <span className="sb3-text-semibold"> #{number}</span>}
            {required && <span className="sb3-text-danger">*</span>}
        </h3>
    )
}

export default Header