import { useAppContext } from 'contexts/AppContext';
import React, { useState, useRef, useEffect } from 'react';

interface CompassProps {
  value?: number;
  onChange?: (value: number) => void;
  size?: number;
  className?: string;
}

const DIRECTIONS = {
  'N': 'North',
  'NE': 'Northeast',
  'E': 'East',
  'SE': 'Southeast',
  'S': 'South',
  'SW': 'Southwest',
  'W': 'West',
  'NW': 'Northwest'
} as const;

const CustomCompass: React.FC<CompassProps> = ({
  value = 0,
  onChange,
  size = 400,
  className = ''
}) => {
    const {
        angle,
        setAngle
    } = useAppContext()
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const compassRef = useRef<HTMLDivElement>(null);
  
    const handleMouseDown = (event: React.MouseEvent) => {
      event.preventDefault();
      setIsDragging(true);
      updateAngle(event);
    };
  
    const handleTouchStart = (event: React.TouchEvent) => {
      event.preventDefault();
      setIsDragging(true);
      updateAngleFromTouch(event);
    };
  
    const updateAngle = (event: React.MouseEvent | MouseEvent) => {
      if (!compassRef.current) return;
  
      const rect = compassRef.current.getBoundingClientRect();
      const centerX = rect.left + rect.width / 2;
      const centerY = rect.top + rect.height / 2;
      
      const x = event.clientX - centerX;
      const y = event.clientY - centerY;
      
      let newAngle = Math.atan2(y, x) * (180 / Math.PI) + 90;
      if (newAngle < 0) newAngle += 360;
  
      setAngle(newAngle);
      onChange?.(newAngle);
    };
  
    const updateAngleFromTouch = (event: React.TouchEvent | TouchEvent) => {
      if (!compassRef.current) return;
  
      const touch = event.touches[0];
      const rect = compassRef.current.getBoundingClientRect();
      const centerX = rect.left + rect.width / 2;
      const centerY = rect.top + rect.height / 2;
      
      const x = touch.clientX - centerX;
      const y = touch.clientY - centerY;
      
      let newAngle = Math.atan2(y, x) * (180 / Math.PI) + 90;
      if (newAngle < 0) newAngle += 360;
  
      setAngle(newAngle);
      onChange?.(newAngle);
    };
  
    useEffect(() => {
      const handleMouseUp = () => {
        setIsDragging(false);
      };
  
      const handleMouseMove = (event: MouseEvent) => {
        if (isDragging) {
          updateAngle(event);
        }
      };
  
      const handleTouchMove = (event: TouchEvent) => {
        if (isDragging) {
          event.preventDefault();
          updateAngleFromTouch(event);
        }
      };
  
      if (isDragging) {
        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('mouseup', handleMouseUp);
        window.addEventListener('touchmove', handleTouchMove, { passive: false });
        window.addEventListener('touchend', handleMouseUp);
      }
  
      return () => {
        window.removeEventListener('mousemove', handleMouseMove);
        window.removeEventListener('mouseup', handleMouseUp);
        window.removeEventListener('touchmove', handleTouchMove);
        window.removeEventListener('touchend', handleMouseUp);
      };
    }, [isDragging]);

  // Get current direction based on angle
  const getCurrentDirection = (angle: number) => {
    const normalizedAngle = ((angle + 22.5) % 360) / 45;
    const directionKeys = Object.keys(DIRECTIONS);
    return directionKeys[Math.floor(normalizedAngle)];
  };

  // Your existing event handlers remain the same ...

  const selectedDirection = getCurrentDirection(angle);

  return (
    <div className="sb3-flex sb3-flex-col sb3-items-center sb3-gap-4">
      <div 
        ref={compassRef}
        className={`sb3-relative ${className}`}
        style={{ width: size, height: size }}
      >
        {/* Main circle */}
        <div 
          className="sb3-absolute sb3-inset-0 sb3-rounded-full sb3-bg-white sb3-shadow-lg"
          onMouseDown={handleMouseDown}
          onTouchStart={handleTouchStart}
        >
          {/* Highlight sector in its own layer */}
          <div className="sb3-absolute sb3-inset-0">
            <div 
              className="sb3-absolute sb3-inset-0 sb3-rounded-full"
              style={{
                background: `conic-gradient(from ${angle - 45}deg at 50% 50%, 
                  rgb(190, 33, 26, 0.7) 0deg, 
                  rgb(190, 33, 26, 0.7) 90deg, 
                  transparent 90deg)`
              }}
            />
          </div>

          {/* Inner circle container with background */}
          <div className="sb3-absolute sb3-inset-14">
            {/* White background circle */}
            <div className="sb3-absolute sb3-inset-0 sb3-rounded-full sb3-bg-white" />
            
            {/* Border circle */}
            <div className="sb3-absolute sb3-inset-0 sb3-rounded-full sb3-border-8 sb3-border-gray-400 sb3-shadow-xl" />

            {/* Direction labels */}
            {Object.entries(DIRECTIONS).map(([dir, _], i) => (
              <span
                key={dir}
                className={`sb3-absolute sb3-text-sm sb3-z-10 !sb3-font-extrabold ${
                  dir === selectedDirection ? 'sb3-text-red-500' : 'sb3-text-black'
                } sb3-font-light`}
                style={{
                  left: '50%',
                  top: '50%',
                  transform: `
                    translate(-50%, -50%) 
                    rotate(${i * 45}deg) 
                    translateY(-${size / 4}px)
                    rotate(-${i * 45}deg)
                  `
                }}
              >
                {dir}
              </span>
            ))}

            {/* Tick marks container */}
            {Array.from({ length: 16 }).map((_, i) => (
              <div
                key={i}
                className="sb3-absolute sb3-py-2 sb3-px-0.5 sb3-h-1 sb3-w-px sb3-bg-gray-200"
                style={{
                  left: '50%',
                  top: '50%',
                  height: '8px',
                  transform: `
                    translate(-50%, -50%) 
                    rotate(${i * 45}deg) 
                    translateY(-${size / 2.5}px)
                  `
                }}
              />
            ))}
          </div>
        </div>
      </div>
      
      {/* Selected direction display */}
      <div className="sb3-text-xl sb3-font-bold sb3-text-red-500 ">
        {DIRECTIONS[selectedDirection as keyof typeof DIRECTIONS]}
      </div>
    </div>
  );
};

export default CustomCompass;